<template>
	<!-- 会员编辑 -->
	<div class="tabulation">
		<el-card class="chart-card">
			<div style="margin-top: 20px;">
				<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
					status-icon>
					<!-- 会员姓名 -->
					<el-form-item label="会员姓名" prop="choice">
						<div class="aaaa">
							<el-input v-model="form.choice" placeholder="请填写会员姓名"></el-input>
						</div>
					</el-form-item>
					<!-- 会员编号 -->
					<el-form-item label="会员编码" prop="name">
						<div class="aaaa">
							<el-input v-model="form.name" readonly placeholder="请填写会员编码"></el-input>
						</div>
					</el-form-item>
					<!-- 所属组织 -->
					<el-form-item label="所属组织" prop="genre">
						<div class="aaaa">
							<el-input v-model="form.genre" readonly placeholder="请选择所属组织"
								@click="visible = true"></el-input>
						</div>
					</el-form-item>
					<!-- 会员密码 -->
					<el-form-item label="会员密码" prop="password">
						<div class="aaaa">
							<el-input v-model="form.password" placeholder="请填写会员密码 (留空默认不更改)"></el-input>
						</div>
					</el-form-item>
					<!-- 手机号 -->
					<el-form-item label="手机号" prop="cellphone">
						<div class="aaaa">
							<el-input v-model="form.cellphone" type="number" placeholder="请填写手机号"></el-input>
						</div>
					</el-form-item>
					<!-- 邮箱 -->
					<el-form-item label="邮箱" prop="mailbox">
						<div class="aaaa">
							<el-input v-model="form.mailbox" placeholder="请填写邮箱"></el-input>
						</div>
					</el-form-item>
					<!-- 会员性别 -->
					<el-form-item label="性别" prop="sex">
						<el-select v-model="form.sex" placeholder="请选择">
							<el-option label="男" value="男" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="女" value="女" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 会员出生日期 -->
					<el-form-item label="出生日期" prop="date">
						<el-date-picker :locale="locale" v-model="form.date" type="date" placeholder="选择日期"
							size="large" />
					</el-form-item>
					<!-- 婚姻状况 -->
					<el-form-item label="婚姻状况" prop="marriage">
						<el-select v-model="form.marriage" placeholder="请选择">
							<el-option label="已婚" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="未婚" value="2" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="离婚" value="3" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 会员民族 -->
					<el-form-item label="民族" prop="folk">
						<el-select v-model="form.folk" placeholder="请选择">
							<el-option v-for="(item) in nation" :key="item" :label="item.name" :value="item.value"
								style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 政治面貌 -->
					<el-form-item label="政治面貌" prop="visage">
						<el-select v-model="form.visage" placeholder="请选择">
							<el-option v-for="(item) in aspect" :key="item" :label="item.name" :value="item.value"
								style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 学历 -->
					<el-form-item label="学历" prop="education">
						<el-select v-model="form.education" placeholder="请选择">
							<el-option v-for="(item) in study" :key="item" :label="item.name" :value="item.value"
								style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 工会角色 -->
					<el-form-item label="工会角色" prop="role">
						<el-select v-model="form.role" placeholder="请选择">
							<el-option v-for="(item) in part" :key="item" :label="item.name" :value="item.value"
								style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 是否复转军人 -->
					<el-form-item label="是否复转军人" prop="soldier">
						<el-select v-model="form.soldier" placeholder="请选择">
							<el-option label="是" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 会员备注 -->
					<el-form-item label="会员备注" prop="remark">
						<el-input v-model="form.remark" type="textarea" placeholder="请填写备注"></el-input>
					</el-form-item>
					<!-- 提交按钮 -->
					<div class="submit">
						<el-form-item>
							<el-button type="primary" :loading="loa" @click="onSubmit">提交</el-button>
							<!-- <el-button @click="onReset">重置</el-button> -->
						</el-form-item>
					</div>
					<div>
						<s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']"
							@yes="yes">
							<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
						</s3-layer>
					</div>
				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		ref,
		reactive,
		onMounted,
		onActivated
	} from "vue";
	import zhCn from 'element-plus/lib/locale/lang/zh-cn'
	import {
		Bjedit,
		Zzlist,
		Bjsave
	} from '../../utils/api'
	import {
		useStore
	} from "vuex";
	import qs from 'qs'
	import {
		ElMessage
	} from "element-plus";
	import {
		useRouter,
		useRoute
	} from "vue-router";
	export default {
		name: "hyedit",
		setup() {
			const route = useRoute();
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.UserId
				if (translate.value == 1) {
					loading.value = true
					obtain()
					suoshu()
				}
			})
			onMounted(() => {
				obtain()
				suoshu()
			})
			// 按钮
			let loa = ref(false)
			// 是否滚动
			let cat = ref('hidden')
			// 加载中
			let loading = ref(true)
			// 所属组织接口
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			let texture = ref([])
			const suoshu = () => {
				Zzlist().then((res) => {
					texture.value = res.data.data.wholeUnionList
					const arr3 = texture.value.filter(function(num) {
						return num.unionCode == hqcode.value
					})
					if (arr3.length == 0) {
						texture.value.forEach((item) => {
							const arr4 = item.unionDtoList.filter(function(num) {
								return num.unionCode == hqcode.value
							})
							if (arr4.length == 0) {
								item.unionDtoList.forEach((items) => {
									const arr5 = items.unionDtoList.filter(function(nums) {
										return nums.unionCode == hqcode.value
									})
									if (arr5.length == 0) {
										items.unionDtoList.forEach((ite) => {
											const arr6 = ite.unionDtoList.filter(
												function(numss) {
													return numss.unionCode ==
														hqcode.value
												})
											if (arr6.length == 0) {
												ite.unionDtoList.forEach((it) => {
													const arr7 = it
														.unionDtoList.filter(
															function(numsss) {
																return numsss
																	.unionCode ==
																	hqcode
																	.value
															})
													if (arr7.length == 0) {
														it.unionDtoList
															.forEach((i) => {
																const
																	arr8 =
																	i
																	.unionDtoList
																	.filter(
																		function(
																			numssss
																		) {
																			return numssss
																				.unionCode ==
																				hqcode
																				.value
																		})
																if (arr8
																	.length ==
																	0) {
																	i.unionDtoList
																		.forEach(
																			(
																				s
																				) => {
																				const
																					arr9 =
																					s
																					.unionDtoList
																					.filter(
																						function(
																							nu
																						) {
																							return nu
																								.unionCode ==
																								hqcode
																								.value
																						}
																					)
																				if (arr9
																					.length ==
																					0
																				) {
																					s.unionDtoList
																						.forEach(
																							(
																								a
																								) => {
																								const
																									arr10 =
																									a
																									.unionDtoList
																									.filter(
																										function(
																											cat
																										) {
																											return cat
																												.unionCode ==
																												hqcode
																												.value
																										}
																									)
																								if (arr10
																									.length ==
																									0
																								) {
																									a.unionDtoList
																										.forEach(
																											(
																												d
																												) => {
																												const
																													arr11 =
																													d
																													.unionDtoList
																													.filter(
																														function(
																															dog
																														) {
																															return dog
																																.unionCode ==
																																hqcode
																																.value
																														}
																													)
																												if (arr11
																													.length ==
																													0
																												) {
																													d.unionDtoList
																														.forEach(
																															(
																																f
																																) => {
																																const
																																	arr12 =
																																	f
																																	.unionDtoList
																																	.filter(
																																		function(
																																			long
																																		) {
																																			return long
																																				.unionCode ==
																																				hqcode
																																				.value
																																		}
																																	)
																																arr12
																																	.forEach(
																																		(
																																			item
																																			) => {
																																			form.genre =
																																				arr3
																																				.unionName =
																																				item
																																				.unionName
																																		}
																																	)
																															}
																														)
																												}
																												arr11
																													.forEach(
																														(
																															item
																															) => {
																															form.genre =
																																arr3
																																.unionName =
																																item
																																.unionName
																														}
																													)
																											}
																										)
																								}
																								arr10
																									.forEach(
																										(
																											item
																											) => {
																											form.genre =
																												arr3
																												.unionName =
																												item
																												.unionName
																										}
																									)
																							}
																						)
																				}
																				arr9.forEach(
																					(
																						item
																						) => {
																						form.genre =
																							arr3
																							.unionName =
																							item
																							.unionName
																					}
																				)
																			}
																		)
																}
																arr8.forEach(
																	(
																		item
																		) => {
																		form.genre =
																			arr3
																			.unionName =
																			item
																			.unionName
																	})
															})
													}
													arr7.forEach((item) => {
														form.genre =
															arr3
															.unionName =
															item
															.unionName
													})
												})
											}
											arr6.forEach((item) => {
												form.genre = arr3.unionName =
													item.unionName
											})
										})
									}
									console.log(arr5)
									arr5.forEach((item) => {
										form.genre = arr3.unionName = item.unionName
									})
								})
							} else {
								arr4.forEach((item) => {
									form.genre = arr3.unionName = item.unionName
								})
							}
						})
					} else {
						arr3.forEach((item) => {
							form.genre = arr3.unionName = item.unionName
						})
					}
					loading.value = false
					cat.value = 'auto'
					console.log(res)
				})
			}
			// 组织弹窗
			// 获取工会code
			const visible = ref(false);
			let gaincode = ref('')
			let options = ref('')
			let dendrogram = (node) => {
				options.value = node.unionName
				form.genre = options.value
				gaincode.value = node.unionCode
			}
			const yes = () => {
				form.genre = options.value
				visible.value = false;
			};
			// 获取编辑信息接口
			// 获取组织code
			let hqcode = ref('')
			const obtain = () => {
				let data = {
					userId: sessionStorage.getItem('user')
				}
				Bjedit(qs.stringify(data)).then((res) => {
					if (res.data.code == 0) {
						// 会员密码
						form.password = res.data.data.userInfo.password
						// 会员生日
						form.date = res.data.data.userInfo.birthday * 1000
						// 会员姓名
						form.choice = res.data.data.userInfo.name
						// 会员编号
						form.name = res.data.data.userInfo.username
						// 会员手机号
						form.cellphone = res.data.data.userInfo.mobile
						// 会员邮箱
						form.mailbox = res.data.data.userInfo.email
						// 会员性别
						if (res.data.data.userInfo.sex == 1) {
							form.sex = '男'
						} else if (res.data.data.userInfo.sex == 2) {
							form.sex = '女'
						} else if (res.data.data.userInfo.sex == null || res.data.data.userInfo.sex == '') {
							form.sex = ''
						}
						// 婚姻状况
						if (res.data.data.userInfo.marry == 1) {
							form.marriage = "已婚"
						} else if (res.data.data.userInfo.marry == 2) {
							form.marriage = "未婚"
						} else if (res.data.data.userInfo.marry == 3) {
							form.marriage = "离婚"
						} else if (res.data.data.userInfo.marry == null || res.data.data.userInfo.marry ==
							'') {
							form.marriage = ""
						}
						// 会员民族
						form.folk = res.data.data.userInfo.nation
						// 政治面貌
						form.visage = res.data.data.userInfo.politicsRole
						// 学历
						form.education = res.data.data.userInfo.education
						// 工会角色
						form.role = res.data.data.userInfo.job
						// 是否复转军人
						if (res.data.data.userInfo.isSoldier == 1) {
							form.soldier = '是'
						} else if (res.data.data.userInfo.isSoldier == 2) {
							form.soldier = '否'
						} else if (res.data.data.userInfo.isSoldier == null || res.data.data.userInfo
							.isSoldier == '') {
							form.soldier = ''
						}
						// 会员备注
						form.remark = res.data.data.userInfo.remarks
						// 获取组织code
						hqcode.value = res.data.data.userInfo.unionCode
						gaincode.value = res.data.data.userInfo.unionCode
					}
					console.log(res)
				})
			}
			// 工会角色
			let part = [{
					name: "主席",
					value: "主席"
				},
				{
					name: "常务副主席",
					value: "常务副主席"
				},
				{
					name: "副主席",
					value: "副主席"
				},
				{
					name: "委员",
					value: "委员"
				},
				{
					name: "工会组长",
					value: "工会组长"
				},
				{
					name: "会员",
					value: "会员"
				},
			]
			// 学历
			let study = [{
					name: "中专一下",
					value: "中专一下"
				},
				{
					name: "中专",
					value: "中专"
				},
				{
					name: "高中",
					value: "高中"
				},
				{
					name: "大专 (高职)",
					value: "大专 (高职)"
				},
				{
					name: "本科",
					value: "本科"
				},
				{
					name: "硕士研究生",
					value: "硕士研究生"
				},
				{
					name: "博士研究生",
					value: "博士研究生"
				},
			]
			// 政治面貌
			let aspect = [{
					name: '中共党员',
					value: '中共党员'
				},
				{
					name: '中共预备党员',
					value: '中共预备党员'
				},
				{
					name: '共青团员',
					value: '共青团员'
				},
				{
					name: '民革党员',
					value: '民革党员'
				},
				{
					name: '民盟盟员',
					value: '民盟盟员'
				},
				{
					name: '民建会员',
					value: '民建会员'
				},
				{
					name: '民进会员',
					value: '民进会员'
				},
				{
					name: '农工党党员',
					value: '农工党党员'
				},
				{
					name: '致公党党员',
					value: '致公党党员'
				},
				{
					name: '九三学社社员',
					value: '九三学社社员'
				},
				{
					name: '台盟盟员',
					value: '台盟盟员'
				},
				{
					name: '无党派人士',
					value: '无党派人士'
				},
				{
					name: '群众',
					value: '群众'
				},
			]
			// 民族
			let nation = [{
					value: '汉族',
					name: '汉族'
				},
				{
					value: '壮族',
					name: '壮族'
				},
				{
					value: '满族',
					name: '满族'
				},
				{
					value: '回族',
					name: '回族'
				},
				{
					value: '苗族',
					name: '苗族'
				},
				{
					value: '维吾尔族',
					name: '维吾尔族'
				},
				{
					value: '土家族',
					name: '土家族'
				},
				{
					value: '彝族',
					name: '彝族'
				},
				{
					value: '蒙古族',
					name: '蒙古族'
				},
				{
					value: '藏族',
					name: '藏族'
				},
				{
					value: '布依族',
					name: '布依族'
				},
				{
					value: '侗族',
					name: '侗族'
				},
				{
					value: '瑶族',
					name: '瑶族'
				},
				{
					value: '朝鲜族',
					name: '朝鲜族'
				},
				{
					value: '白族',
					name: '白族'
				},
				{
					value: '哈尼族',
					name: '哈尼族'
				},
				{
					value: '哈萨克族',
					name: '哈萨克族'
				},
				{
					value: '黎族',
					name: '黎族'
				},
				{
					value: '傣族',
					name: '傣族'
				},
				{
					value: '畲族',
					name: '畲族'
				},
				{
					value: '傈僳族',
					name: '傈僳族'
				},
				{
					value: '仡佬族',
					name: '仡佬族'
				},
				{
					value: '东乡族',
					name: '东乡族'
				},
				{
					value: '高山族',
					name: '高山族'
				},
				{
					value: '拉祜族',
					name: '拉祜族'
				},
				{
					value: '水族',
					name: '水族'
				},
				{
					value: '佤族',
					name: '佤族'
				},
				{
					value: '纳西族',
					name: '纳西族'
				},
				{
					value: '羌族',
					name: '羌族'
				},
				{
					value: '土族',
					name: '土族'
				},
				{
					value: '仫佬族',
					name: '仫佬族'
				},
				{
					value: '锡伯族',
					name: '锡伯族'
				},
				{
					value: '柯尔克孜族',
					name: '柯尔克孜族'
				},
				{
					value: '达斡尔族',
					name: '达斡尔族'
				},
				{
					value: '景颇族',
					name: '景颇族'
				},
				{
					value: '毛南族',
					name: '毛南族'
				},
				{
					value: '撒拉族',
					name: '撒拉族'
				},
				{
					value: '布朗族',
					name: '布朗族'
				},
				{
					value: '塔吉克族',
					name: '塔吉克族'
				},
				{
					value: '阿昌族',
					name: '阿昌族'
				},
				{
					value: '普米族',
					name: '普米族'
				},
				{
					value: '鄂温克族',
					name: '鄂温克族'
				},
				{
					value: '怒族',
					name: '怒族'
				},
				{
					value: '京族',
					name: '京族'
				},
				{
					value: '基诺族',
					name: '基诺族'
				},
				{
					value: '德昂族',
					name: '德昂族'
				},
				{
					value: '保安族',
					name: '保安族'
				},
				{
					value: '俄罗斯族',
					name: '俄罗斯族'
				},
				{
					value: '裕固族',
					name: '裕固族'
				},
				{
					value: '乌孜别克族',
					name: '乌孜别克族'
				},
				{
					value: '门巴族',
					name: '门巴族'
				},
				{
					value: '鄂伦春族',
					name: '鄂伦春族'
				},
				{
					value: '独龙族',
					name: '独龙族'
				},
				{
					value: '塔塔尔族',
					name: '塔塔尔族'
				},
				{
					value: '赫哲族',
					name: '赫哲族'
				},
				{
					value: '珞巴族',
					name: '珞巴族'
				}
			]
			// 树形选择内容
			let choiceValue = ref('')
			let selectTree1 = ref()
			const handleNodeClick = (data) => {
				choiceValue.value = data
				form.choice = data.label
				selectTree1.value.blur();
				console.log(choiceValue.value)
			}
			const data = [{
					label: '一级 1',
					children: [{
						label: '一级 1-1',
					}]
				},
				{
					label: '二级 2',
					children: [{
						label: '二级 2-1',
					}]
				},
				{
					label: '二级 2',
					children: [{
						label: '二级 2-1',
					}]
				},
				{
					label: '二级 2',
					children: [{
						label: '二级 2-1',
					}]
				},
				{
					label: '二级 2',
					children: [{
						label: '二级 2-1',
					}]
				},
				{
					label: '二级 2',
					children: [{
						label: '二级 2-1',
					}]
				}
			]
			// 表单内容
			const ruleFormRef = ref(null)
			const form = reactive({
				// 编号
				name: "",
				// 所属工会
				genre: "",
				// 姓名
				choice: "",
				// 性别
				sex: "",
				// 出生日期
				date: "Tue Jul 05 2022 00:00:00 GMT+0800 (中国标准时间)",
				// 民族
				folk: "",
				// 政治面貌
				visage: "",
				// 学历
				education: "",
				// 工会角色
				role: "",
				// 是否复转军人
				soldier: "",
				// 手机号
				cellphone: "",
				// 会员密码
				password: '',
				// 会员邮箱
				mailbox: "",
				// 婚姻状况
				marriage: "",
				// 会员备注
				remark: ""
			})
			// 时间转换
			let transition = (date) => {
				let result = new Date(date).getTime();
				return result;
			}
			// 重置按钮
			// const onReset = ()=>{
			//   ruleFormRef.value.resetFields();
			// }
			// 表单提交
			const store = useStore();
			const router = useRouter();
			// 关闭当前页
			const current = ref('')
			const expression = ref([])
			const Indexs = ref(null)

			const onSubmit = () => {




				// 表单校验
				ruleFormRef.value.validate((valid) => {
					if (valid == false) {
						ElMessage({
							message: "提交失败",
							type: "error",
							center: true,
						});
					} else if (valid == true) {
						loa.value = true
						// 婚姻状况
						let ndition = ref(null)
						if (form.marriage == '已婚') {
							ndition.value = 1
						} else if (form.marriage == '未婚') {
							ndition.value = 2
						} else if (form.marriage == '离婚') {
							ndition.value = 3
						} else if (form.marriage == 1) {
							ndition.value = 1
						} else if (form.marriage == 2) {
							ndition.value = 2
						} else if (form.marriage == 3) {
							ndition.value = 3
						}
						// 是否复转军人
						let no = ref(null)
						if (form.soldier == '是') {
							no.value = 1
						} else if (form.soldier == '否') {
							no.value = 2
						} else if (form.soldier == 1) {
							no.value = 1
						} else if (form.soldier == 2) {
							no.value = 2
						}
						// 性别
						let sexs = ref(null)
						if (form.sex == '男') {
							sexs.value = 1
						} else if (form.sex == '女') {
							sexs.value = 2
						} else if (form.sex == 1) {
							sexs.value = 1
						} else if (form.sex == 2) {
							sexs.value = 2
						}
						let data = {
							userId: sessionStorage.getItem('user'),
							username: form.name,
							name: form.choice,
							unionCode: gaincode.value,
							password: form.password,
							sex: sexs.value,
							birthday: transition(form.date) / 1000,
							nation: form.folk,
							politicsRole: form.visage,
							education: form.education,
							job: form.role,
							isSoldier: no.value,
							mobile: form.cellphone,
							marry: ndition.value,
							email: form.mailbox,
							remarks: form.remark
						}
						Bjsave(qs.parse(data)).then((res) => {
							if (res.data.code == 0) {
								ElMessage({
									message: "编辑成功",
									type: "success",
									center: true,
								});
								setTimeout(() => {
									// router.push({ name: 'member', params: { userId: 1 }});
									// 关闭当前页
									current.value = route.path
									expression.value = store.state.tagsList

									expression.value.forEach((item, index) => {
										if (current.value == item.path) {
											Indexs.value = index
										}
									})
									store.state.tagsList.splice(Indexs.value, 1)
									router.push({
										name: 'member',
										params: {
											userId: 1
										}
									});
									// form.choice=''
									//   form.name=''
									//   form.genre=''
									//   zzcode.value=''
									//   form.password=''
									//   form.sex=''
									//   form.date=''
									//   form.folk=''
									//   form.visage=''
									//   form.education=''
									//   form.role=''
									//   form.soldier=''
									//   form.cellphone=''
									//   form.marriage=''
									//   form.mailbox=''
									//   form.remark=''
									obtain()
									suoshu()
									loa.value = false
								}, 500)
							} else {
								ElMessage({
									message: res.data.msg,
									type: "error",
									center: true,
								});
								loa.value = false
							}
							console.log(res)
						})
					}
					// console.log(valid)
					// console.log(form.mailbox)
					// console.log(form.soldier)
					// console.log(form.remark)
					// console.log(form.marriage)
					// console.log(form.password)
					// console.log(form.cellphone)
					// console.log(form.role)
					// console.log(form.education)
					// console.log(form.visage)
					// console.log(form.folk)
					// console.log(form.date)
					// console.log(form.sex)
					// console.log(form.choice)
					// console.log(form.genre)
					// console.log(form.name)
				})
			}
			// 验证表单提示
			const rules = {
				name: [{
					required: true,
					message: "请填写会员编码",
					trigger: "blur"
				}, ],
				genre: [{
					required: true,
					message: "请选择组织类型",
					trigger: "change"
				}, ],
				choice: [{
					required: true,
					message: "请填写会员姓名",
					trigger: "blur"
				}, ],
			}
			return {
				cat,
				// 加载中
				loading,
				defaultProps,
				texture,
				visible,
				dendrogram,
				yes,
				// 工会角色
				part,
				// 学历
				study,
				// 政治面貌
				aspect,
				// 民族
				nation,
				// 中文转换
				locale: zhCn,
				// 树形选择内容
				selectTree1,
				handleNodeClick,
				choiceValue,
				data,
				// 表单内容
				ruleFormRef,
				form,
				// 重置按钮
				// onReset,
				// 表单提交按钮
				onSubmit,
				rules
			}
		},
	}
</script>>

<style lang='scss' scoped>
	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	::v-deep .chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	::v-deep .subject {
		margin-left: 3px;
		font-size: var(--el-form-label-font-size);
		font-weight: bold;
		margin-bottom: 5px;
		color: var(--el-text-color-regular);

		.xingxing {
			color: var(--el-color-danger);
		}
	}

	// .w-e-text-container [data-slate-editor] p{
	//   margin-top: 22px;
	//   margin-bottom: 22px;
	// }
	::v-deep .el-button {
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	::v-deep .el-form-item__content {
		align-items: flex-end;
	}

	::v-deep .referrals {
		color: #cccccc;
		margin-left: 5px;
		font-weight: 500;
	}

	::v-deep .styB .el-upload--picture-card {
		width: 110px;
		height: 110px;
		line-height: 110px;
	}

	::v-deep .styC .el-upload--picture-card {
		display: none;
	}

	::v-deep .el-form-item {
		align-items: center;
	}

	::v-deep .aaaa {
		width: 50%;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	::v-deep .el-main {
		overflow: auto !important;
	}

	::v-deep .el-card.is-always-shadow {
		width: 95%;
		margin: auto;
		margin-top: 10px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		overflow: auto !important;
	}
</style>
<style scoped>
	::v-deep .upload-demo {
		display: flex;
		width: 100%;
	}

	::v-deep .upload-demo .el-upload-list {
		display: flex;
		width: 50%;
	}

	::v-deep .el-input {
		height: 44px !important;
	}

	::v-deep .el-form-item__label {
		width: 125px !important;
		font-weight: bold;
	}

	::v-deep .submit {
		margin-left: 125px;
	}
</style>